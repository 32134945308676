<template>
    <div class="chat-button" @click="toggleChat">
        <img src="@/assets/chatbot-images/Chat-icon.svg" alt="Chat Icon" class="chat-icon" />
    </div>
</template>

<script>
export default {
    methods: {
        toggleChat() {
            this.$emit("toggle-chat");
        },
    },
};
</script>

<style scoped>
.chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 998;
    background-image: linear-gradient(120deg, #2196f3 0%, #c084fc 82%);
    color: white;
    height: 60px;
    width: 60px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.2s;
}

.chat-button:hover {
    transform: scale(1.05);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.24);
}

.chat-button .chat-icon {
    width: 25px;
    height: auto;
}
</style>
