<template>
    <div v-if="show" class="fixed left-0 right-0 top-0 bottom-0 z-50 flex items-center justify-center h-screen w-full bg-[rgba(0,0,0,0.3)]">
        <div @click="toggleModal" class="fixed left-0 right-0 top-0 bottom-0 -z-10 h-screen"></div>
        <div class="w-1/2 h-[90%] p-10 rounded-lg bg-white flex flex-col justify-start gap-8 items-center overflow-y-scroll">
            <div class="w-full flex flex-row justify-between items-center">
                <h1 class="text-xl font-bold text-slate-700 text-left">How to interpret the result</h1>
                <button @click="toggleModal" class="text-slate-700 text-base"><font-awesome-icon :icon="['fas', 'xmark']" /></button>
            </div>
            <p class="text-base font-thin text-slate-700 text-left">
                GO-Platform offers two main scoring methods to help you interpret the performance of your candidates: <b class="font-bold"> Percentage correct</b> and
                <b class="font-bold"> Percentile scoring</b> .
            </p>
            <AssessementTabs>
                <AssessementTab type="Percentage correct answers" class="flex flex-col items-center justify-start gap-[3rem]">
                    <p class="text-base font-thin text-slate-700 text-left mt-4">
                        A measure of how well a candidate performed on a test, calculated by taking the number of points scored and dividing it by the total number of points available on the test,
                        then multiplying by 100 to express the result as a percentage. For example, if a candidate answered 5 questions correctly out of 10 questions on a test where each question was
                        worth 1 point, then they scored 5/10 points, and their percentage correct would be 50% because 5/10 x 100 = 50%.
                        <br /><br />
                        Percentage correct is a simple way to understand a person's performance on a test, but it <b class="font-bold"> does not</b> take into account the difficulty of the test.
                    </p>
                    <div class="flex flex-row justify-between items-center w-full h-fit gap-4">
                        <div class="w-1/2 flex flex-col justify-start items-center gap-6">
                            <h1 class="text-lg font-bold text-slate-800 text-left w-full">Average score</h1>
                            <p class="text-base font-thin text-slate-700 text-left pb-4">
                                Represents the mathematical average of the candidate's percentage correct score across individual tests in the assessment, rounded to the nearest whole number. The
                                results of any personality tests are excluded from this average because we do not recommend that these tests be included in ranking candidates to make a hiring
                                decision.
                                <br /><br />
                                In this example the average score of 80% is calculated by averaging the results of four out of five tests in an assessment (excluding the 16 types personality test).
                            </p>
                        </div>
                        <div class="w-1/2 h-full bg-gray-200 rounded-lg flex flex-col justify-between items-center py-5 px-4 gap-3">
                            <div class="flex flex-row justify-between items-center w-full mb-4">
                                <h1 class="text-lg font-bold text-slate-800 text-left">Average score</h1>
                                <h1 class="text-lg font-bold text-slate-800 text-left">75%</h1>
                            </div>
                            <div class="w-full flex flex-row justify-between items-center bg-white rounded-md p-4">
                                <h3 class="text-base font-light text-left text-slate-700">Critical thinking</h3>
                                <h3 class="text-base font-light text-left text-slate-700">77%</h3>
                            </div>
                            <div class="w-full flex flex-row justify-between items-center bg-white rounded-md p-4">
                                <h3 class="text-base font-light text-left text-slate-700">exploratory data analysis</h3>
                                <h3 class="text-base font-light text-left text-slate-700">83%</h3>
                            </div>
                            <div class="w-full flex flex-row justify-between items-center bg-white rounded-md p-4">
                                <h3 class="text-base font-light text-left text-slate-700">Verbal reasoning</h3>
                                <h3 class="text-base font-light text-left text-slate-700">68%</h3>
                            </div>
                            <div class="w-full flex flex-row justify-between items-center bg-white rounded-md p-4">
                                <h3 class="text-base font-light text-left text-slate-700">Culture add</h3>
                                <h3 class="text-base font-light text-left text-slate-700">92%</h3>
                            </div>
                            <div class="flex flex-col justify-between items-start w-full gap-3">
                                <h2 class="text-base font-bold text-slate-800 text-left">Personality</h2>
                                <div class="w-full flex flex-row justify-between items-center bg-white rounded-md p-3">
                                    <h3 class="text-base font-light text-left text-slate-700">16 types</h3>
                                    <h3 class="text-base font-bold text-left text-slate-700 bg-slate-300 rounded p-1">nFtj</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </AssessementTab>
                <AssessementTab type="Percentile scoring" class="flex flex-col items-start justify-between gap-6 py-5">
                    <p class="text-base font-thin text-slate-700 text-left">
                        Percentile scoring allows you to easily compare your candidate's performance relative to other candidates by choosing a comparison group. For example, if a candidate’s score is
                        in the 87th percentile, this means that 87% of candidates in the comparison group have scored lower than the candidate. Percentile scoring is helpful for comparing how a
                        candidate performs relative to other candidates in a given comparison group.
                    </p>
                    <h1 class="font-bold text-base text-slate-800 text-left mt-5">Comparison groups</h1>
                    <p class="text-base font-thin text-slate-700 text-left">
                        When you select the percentile scoring method, the default comparison group that your candidate is compared to is All candidates who have taken this test (across all jobs,
                        levels and organizations in our database). We also offer a variety of more specific comparison groups that can make the comparison more nuanced, allowing you to compare
                        candidates in our database based on highest educational attainment, business function, or level of seniority.
                    </p>
                    <div class="flex flex-col items-center relative justify-between">
                        <img loading="lazy" decoding="async" src="@/assets/Images/percentail_bell_curve.png" width="100" height="50" alt="percentile chart" class="w-full h-80" />
                        <span class="text-sm font-thin p-2 w-44 text-slate-700 absolute top-5 right-5 rounded bg-white"
                            >Candidate name scored as well or better than, 68% of candidates in comparison group: All candidates.</span
                        >
                    </div>
                    <p class="text-base font-thin text-slate-700 text-left">
                        Please note that Coding tests and Culture add tests are always scored using the Percentage of correct answers scoring method, even if you select Percentile scoring. In other
                        words, the scores on these tests are never relative to other candidates.
                    </p>
                </AssessementTab>
            </AssessementTabs>
        </div>
    </div>
</template>

<script>
import AssessementTab from "@/components/assessementTab.vue";
import AssessementTabs from "@/components/assessementTabs.vue";
export default {
    name: "InterpretResult",
    props: {
        show: Boolean,
        toggleModal: Function,
    },
    components: {
        AssessementTab,
        AssessementTabs,
    },
};
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 0.75rem;
}
::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    border-radius: 0.5rem;
}
</style>
