<template>
    <section class="chat-window">
        <div class="chatbot-header">
            <h3>GO PLATFORM</h3>
            <!-- Tab Bar -->
            <div class="tab-bar">
                <button v-for="(tab, index) in tabs" :key="index" :class="['tab-button', { active: activeTab === index }]" @click="setActiveTab(index)">
                    {{ tab.label }}
                </button>
            </div>

            <font-awesome-icon :icon="['fas', 'xmark']" class="close-icon" @click="closeChat" />
        </div>

        <div class="chat-container">
            <div class="messages-wrapper" ref="messagesContainer" @click="handleLinkClick">
                <!-- Welcome Section -->
                <div v-if="showWelcome && !hasInteracted" class="welcome-section">
                    <div class="welcome-content">
                        <img src="@/assets/chatbot-images/logo.svg" alt="Chatbot Logo" class="welcome-logo" />
                        <h2 class="welcome-title">How can we <span class="gradient">Assist</span> you today?</h2>
                        <p class="welcome-subtitle">
                            Get expert guidance powered by AI agents specializing in Human Resources. Choose the agent that suits your needs and start your conversation with ease.
                        </p>
                    </div>

                    <div class="quick-replies-container" v-if="activeTab === 0">
                        <div v-for="(reply, index) in quickReplies" :key="index" class="quick-reply-card" @click="handleQuickReply(reply)">
                            <div class="card-header">
                                <h4 class="card-title">{{ reply.title }}</h4>
                                <img src="@/assets/chatbot-images/card-icon.svg" alt="Card Icon" class="card-icon" />
                            </div>
                            <p class="card-subtitle">{{ reply.subtitle }}</p>
                        </div>
                    </div>
                </div>

                <!-- Messages -->
                <div v-for="(message, index) in messages" :key="index" :class="message.role">
                    <!-- Error Message -->
                    <div v-if="message.role === 'error'" class="error">
                        <img src="@/assets/chatbot-images/bot-avatar.svg" alt="Bot Avatar" class="bot-avatar" />
                        <p>{{ message.parts[0].text }}</p>
                    </div>

                    <!-- Bot Message -->
                    <template v-else-if="message.role === 'model'">
                        <img src="@/assets/chatbot-images/bot-avatar.svg" alt="Bot Avatar" class="bot-avatar" />
                        <p v-html="formatMessage(message.parts[0].text)"></p>
                    </template>

                    <!-- User Message -->
                    <template v-else>
                        <font-awesome-icon :icon="['fas', 'circle-user']" class="user-avatar" />
                        <p>{{ message.parts[0].text }}</p>
                    </template>
                </div>

                <!-- Loading Indicator -->
                <div v-if="isLoading" class="loading-indicator">
                    <img src="@/assets/chatbot-images/bot-avatar.svg" alt="Bot Avatar" class="bot-avatar" />
                    <div class="loader"></div>
                </div>
            </div>
        </div>
        <div class="input-area">
            <div class="input-container">
                <input v-model="userInput" placeholder="Type your prompt here" @keydown.enter="!isLoading && !isTyping && sendMessage()" :disabled="isLoading || isTyping" />
                <div class="send-icon-container" @click="!isLoading && !isTyping && sendMessage()" :class="{ disabled: isLoading || isTyping }">
                    <font-awesome-icon :icon="['fas', 'arrow-up']" class="send-icon" :class="{ 'disabled-icon': isLoading || isTyping }" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { marked } from "marked";
import DOMPurify from "dompurify";
export default {
    props: {
        messages: Array,
        isLoading: Boolean,
        isTyping: Boolean,
        hasInteracted: Boolean,
    },
    data() {
        return {
            userInput: "",
            showWelcome: true, // Controls visibility of welcome message
            quickReplies: [
                {
                    title: "Sales Manager Job Description",
                    subtitle: "Learn Skills based Job Description and transform your organization.",
                    prompt: "Give me a skills-based job description for the Sales Manager position (technical, interpersonal, and behavioral skills)",
                },
                {
                    title: "AI Engineer Skills",
                    subtitle: "Get very Specific Idea on skills needed to hire an AI Engineer.",
                    prompt: "What are the skills (technical, interpersonal, and behavioral skills) to evaluate for an AI Engineer?",
                },
                {
                    title: "What is Skills Gap",
                    subtitle: "Discover step by step guide to identify the skills gap in your organization.",
                    prompt: "What is a skills gap in a company and how it will affect the organization in negative and positive way.",
                },
                {
                    title: "General Support",
                    subtitle: "Need help with something else? Ask away, and we'll guide you.",
                    prompt: "General support for HR Effort only, nothing outside of the box of HR",
                },
            ],
            activeTab: 0, // Default to General tab
            tabs: [
                { label: "General", domain: "General" },
                { label: "Skills", domain: "Skills" },
                { label: "Recruitment", domain: "Recruitment" },
                { label: "HR Management", domain: "HR Management" },
            ],
        };
    },
    methods: {
        sendMessage() {
            if (this.userInput.trim() && !this.isLoading && !this.isTyping) {
                this.showWelcome = false; // Hide welcome section
                this.$emit("send-message", this.userInput);
                this.userInput = "";
            }
        },
        handleQuickReply(reply) {
            this.showWelcome = false; // Hide welcome section
            this.$emit("send-message", reply.prompt); // Use the prompt as message
        },
        closeChat() {
            this.$emit("close-chat");
        },
        setActiveTab(index) {
            this.activeTab = index;
            this.$emit("set-domain", this.tabs[index].domain); // Emit the selected domain
        },
        formatMessage(text) {
            try {
                // Configure marked to match original settings
                marked.setOptions({
                    breaks: true,
                    headerIds: false,
                    mangle: false,
                });

                // Parse markdown
                const parsed = marked.parse(text);

                // Sanitize using original settings
                return DOMPurify.sanitize(parsed, {
                    ALLOWED_TAGS: ["p", "ul", "li", "strong", "em", "a", "br"],
                    ALLOWED_ATTR: ["href", "target"],
                });
            } catch (error) {
                console.error("Formatting error:", error);
                return text;
            }
        },
        handleLinkClick(event) {
            if (event.target.tagName === "A") {
                event.preventDefault();
                window.open(event.target.href, "_blank");
            }
        },

        scrollToBottom() {
            const container = this.$refs.messagesContainer;
            if (container) {
                // Use smooth scrolling
                container.scrollTo({
                    top: container.scrollHeight,
                    behavior: "smooth",
                });
            }
        },
    },
    watch: {
        // Automatically scroll when messages change
        messages: {
            handler() {
                this.$nextTick(() => {
                    this.scrollToBottom();
                });
            },
            deep: true,
        },
    },
    // Scroll when loading state changes
    isLoading() {
        this.$nextTick(() => {
            this.scrollToBottom();
        });
    },

    // Add watcher to reset welcome state when switching tabs
    activeTab(newVal) {
        if (newVal === 0) {
            // General tab index
            this.showWelcome = true;
        }
    },
};
</script>

<style scoped>
.chat-window {
    font-family: "Raleway", sans-serif;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    height: 100vh;
    width: 100vw;
    background-image: url("@/assets/chatbot-images/Chatbot-background.png");
    background-size: cover;
    background-position: center;
    flex-direction: column;
    padding: 10px 25px;
}

.chatbot-header {
    height: 60px;
    display: flex;
    align-items: center;
    position: relative; /* For absolute positioning of the tab bar */
}

.chatbot-header h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    flex: 1;
    color: #2196f3;
}

/* Tab Bar */
.tab-bar {
    display: flex;
    gap: 8px;
    background: #f7f7f7;
    border: 1.5px solid #ededed;
    border-radius: 30px;
    padding: 3px;
    position: absolute; /* Position absolutely within the header */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for exact centering */
}

.tab-button {
    background: none;
    border: none;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: 600;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 20px;
    transition: all 0.2s ease;
}

.tab-button:hover {
    background: rgba(0, 0, 0, 0.05);
}

.tab-button.active {
    background: #2196f3; /* Blue background for active tab */
    color: #fff;
    font-weight: 600;
}

.close-icon {
    color: #000;
    font-size: 20px;
    cursor: pointer;
}

/*************/

/* Welcome Section */
.welcome-section {
    padding: 20px;
    text-align: center;
}

.welcome-content {
    margin-bottom: 40px;
}

.welcome-logo {
    width: 30px;
    height: auto;
    margin: 0 auto 20px auto; /* Center horizontally */
}

.welcome-title {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    margin-bottom: 8px;
}

.welcome-title .gradient {
    font-weight: 600;
    background: linear-gradient(90deg, #2196f3, #8455fd);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    color: transparent;
}

.welcome-subtitle {
    color: #767676;
    font-size: 16px;
    width: 50%;
    margin: 0 auto;
}

/* Quick Replies Container */
.quick-replies-container {
    display: flex;
    gap: 15px;
    max-width: 1000px; /* Adjusted for four cards */
    margin: 0 auto;
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    justify-content: center; /* Center cards horizontally */
}

.quick-reply-card {
    background: white;
    border: 1px solid #e2e8f0;
    border-radius: 15px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    text-align: left;
    flex: 1 1 calc(25% - 15px); /* Four cards per row with gap */
    box-sizing: border-box; /* Include padding in width calculation */
}

.quick-reply-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-color: #2196f3;
}

.card-header {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 8px;
}

.card-title {
    color: #666666;
    font-size: 16px;
    font-weight: 600;
    margin: 0; /* Remove default margin */
}

.card-subtitle {
    color: #767676;
    font-size: 14px;
    margin: 0;
}

.card-icon {
    color: #2196f3;
    font-size: 14px;
    transition: all 0.2s ease;
}

.quick-reply-card:hover .card-icon {
    transform: scale(1.1);
}

.chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.messages-wrapper {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
}

.model p,
.user p,
.error p {
    width: fit-content;
    max-width: 75%;
    min-width: min-content;
    padding: 12px 16px;
    margin: 0;
    font-size: 0.95rem;
    line-height: 24px;
}

.model p {
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #e2e8f0;
    color: #000;
}

.user p {
    background-image: linear-gradient(to right, #2196f3, #c084fc);
    color: white;
    border-radius: 16px;
}

.error p {
    background-color: #ffffff;
    border-radius: 16px;
    border: 1px solid #e2e8f0;
    color: #d32f2f;
}

.model,
.user,
.error {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin: 20px 0;
}

.model {
    flex-direction: row;
}

.user {
    flex-direction: row-reverse;
}

.bot-avatar,
.user-avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-top: 4px;
}

.user-avatar {
    color: #e1e8fe;
}

.input-area {
    flex-shrink: 0;
    height: 80px;
    display: flex;
    align-items: center;
}

.input-container {
    position: relative;
    width: 100%;
}

input {
    width: 100%;
    height: 50px;
    border: 1px solid #8c8c8c1a;
    background-color: #8c8c8c3d;
    border-radius: 25px;
    border-radius: 25px;
    padding: 0 50px 0 20px;
    font-size: 16px;
}

input:focus {
    outline: none;
}

input::placeholder {
    color: #424242;
}

input:disabled {
    background-color: #f0f0f0;
    cursor: not-allowed;
}

.send-icon-container {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #2196f3;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

.send-icon-container.disabled {
    background-color: #90caf9;
    cursor: not-allowed;
}

.send-icon {
    color: #ffffff;
    font-size: 16px;
}

.disabled-icon {
    opacity: 0.7;
}

.send-icon-container:not(.disabled):hover {
    transform: translateY(-50%) scale(1.05);
}

/* Loading Indicator Styles */
.loading-indicator {
    display: flex;
    align-items: center;
    gap: 12px; /* Space between avatar and loader */
    margin: 20px 0;
}

.loader {
    width: 25px;
    opacity: 0.4;
    aspect-ratio: 4;
    background: radial-gradient(circle closest-side, #000 90%, #0000) 0 / calc(100% / 3) 100% space;
    clip-path: inset(0 100% 0 0);
    animation: l1 1.5s steps(4) infinite;
}

@keyframes l1 {
    to {
        clip-path: inset(0 -34% 0 0);
    }
}

/* Custom scrollbar styling 
.messages-wrapper::-webkit-scrollbar {
    width: 6px; 
}

.messages-wrapper::-webkit-scrollbar-track {
    background: #8c8c8c1a;
    border-radius: 4px;
}

.messages-wrapper::-webkit-scrollbar-thumb {
    background: #2196f3;
    border-radius: 4px;
}
*/

/* .model p ::v-deep strong {
    font-weight: 600;
}

.model p ::v-deep em {
    font-style: italic;
}

.model p ::v-deep ul {
    margin: 8px 0 !important;
    padding-left: 24px !important;
    list-style-type: disc;
}

.model p ::v-deep li {
    margin: 4px 0 !important;
    padding-left: 8px !important;
    line-height: 1.4;
}

.model p ::v-deep a {
    color: #2196f3;
    text-decoration: underline;
}

.model p ::v-deep a:hover {
    text-decoration: none;
} */
</style>
