<template>
    <AssessementPreview :assessment="preview" :showPreview="showPreview" :togglePreview="togglePreview" :addAssessment="addAssessment" />
    <div @scroll="handleScroll">
        <div class="library-section">
            <div class="myAssessments">
                <div class="library-grid bg-red-500">
                    <LibraryCard v-for="(assess, index) in yourAssessment" :key="index" :assessement="assess" @click="OpenPreview(assess)" @delete="deleteAssessement(assess)" />
                </div>
            </div>

            <div class="libraryWrapper relative">
                <div v-if="isLoading" class="loader">
                    <LoadingComponent />
                </div>
                <div class="library" v-else>
                    <!-- <div class="bg-[#2371b631] mb-[2rem] rounded w-full h-[fit-content] p-[2%]" v-if="this.Store.premium && recommendedAssessments.length > 0">
                        <h2 class="text-large" style="font-weight: 600">{{ $t("Recommended") }}:</h2>
                        <div class="library-grid-top w-full">
                            <TopAssessement
                                :removeAssessment="removeAssessment"
                                v-for="(assess, index) in recommendedAssessments"
                                :key="index"
                                :assessement="assess"
                                :placeholders="placeholders"
                                @openPreview="OpenPreview(assess)"
                                :addAssessment="addAssessment"
                            />
                        </div>
                    </div> -->

                    <div class="bg-[#2371b631] mb-[2rem] rounded w-full h-[fit-content] p-[2%]">
                        <h2 v-if="selectedOption === 'essentials'" class="text-[24px]" style="font-weight: 600">{{ $t("Essential skills") }}:</h2>
                        <h2 v-else class="text-[24px]" style="font-weight: 600">{{ $t("Recommended Skills") }}:</h2>
                        <div class="library-grid-top w-full">
                            <TopAssessement
                                :removeAssessment="removeAssessment"
                                :placeholders="placeholders"
                                v-for="(assess, index) in recommendedAssessments"
                                :key="index"
                                :assessement="assess"
                                @openPreview="OpenPreview(assess)"
                                :addAssessment="addAssessment"
                            />
                        </div>
                    </div>
                    <upgradePlan v-if="!this.Store.premium" />

                    <div class="w-full relative flex mt-[4rem] mb-2 items-center justify-between">
                        <div class="fixed top-0 left-0 w-full h-full" v-if="isOpen" @click="toggleFilter"></div>
                        <input
                            placeholder="Search Test"
                            class="!shadow-m !text-sm !py-1 !px-3 !border !border-[1.5px] !rounded-md !h-[3rem] w-[20%] !border-[#e7e7e9]"
                            type="text"
                            id="searchInput"
                            name="searchInput"
                            v-model="searchText"
                            required
                        />
                        <button
                            @click="toggleFilter()"
                            class="bg-white border-[#e7e7e9] !border !border-[1.5px] text-gray-700 inline-flex items-center justify-center text-m rounded min-h-[30px] px-4 py-[10px] font-m focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            Filters <font-awesome-icon class="ml-2" :icon="['fas', 'caret-down']" />
                        </button>
                        <div v-if="isOpen" class="p-4 absolute w-[20%] shadow-xl bg-[#fff] overflow-y-scroll z-10 top-[102%] right-0">
                            <div>
                                <h3 class="text-[16px] mb-2" style="font-weight: 600">Per category:</h3>
                                <label v-for="(category, index) in categories" :key="index" class="checkbox-label">
                                    <input type="checkbox" :value="category" v-model="selectedCategories" @change="filterAssessments" class="checkbox-input" />
                                    <span class="checkbox-text">{{ category }}</span>
                                </label>
                            </div>
                            <div>
                                <h3 class="text-[16px] mb-2" style="font-weight: 600">Per library:</h3>
                                <label v-for="(library, index) in libraries" :key="index" class="checkbox-label">
                                    <input type="checkbox" :value="library" v-model="selectedLibraries" @change="filterAssessments" class="checkbox-input" />
                                    <span class="checkbox-text">{{ library }}</span>
                                </label>
                            </div>
                            <div>
                                <h3 class="text-[16px] mt-4 mb-2" style="font-weight: 600">Test Duration:</h3>
                                <label v-for="(duration, index) in testDurations" :key="index" class="checkbox-label">
                                    <input type="checkbox" :value="duration" v-model="selectedDurations" @change="SearchByDuration" class="checkbox-input" />
                                    <span class="checkbox-text text-[5px]">{{ duration }}</span>
                                </label>
                            </div>
                            <div>
                                <h3 class="text-[16px] mt-4 mb-2" style="font-weight: 600">Test Focus:</h3>
                                <label v-for="(focus, index) in testFucus" :key="index" class="checkbox-label">
                                    <input type="checkbox" :value="focus" v-model="selectedFocus" @change="filterAssessments" class="checkbox-input" />
                                    <span class="checkbox-text">{{ focus }}</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="library-grid-top z-10 w-full">
                        <TopAssessement
                            :removeAssessment="removeAssessment"
                            :placeholders="placeholders"
                            v-for="(assess, index) in filteredAssessments"
                            :key="index"
                            :assessement="assess"
                            @openPreview="OpenPreview(assess)"
                            :addAssessment="addAssessment"
                        />
                    </div>
                    <div class="flex items-center justify-center h-[300px] w-full">
                        <span class="text-2xl text-[#2196f3]">Your library is empty</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LibraryCard from "@/components/LibraryCard.vue";
import AssessementPreview from "@/components/dashboard/library/AssessementPreview.vue";
import TopAssessement from "@/components/TopAssessement.vue";
import { useStore } from "@/store/index";
import axios from "axios";
import LoadingComponent from "@/components/LoadingComponent.vue";
import upgradePlan from "@/components/upgradePlan.vue";
export default {
    name: "NewAssessLib",
    components: {
        LibraryCard,
        AssessementPreview,
        TopAssessement,
        LoadingComponent,
        // AssessementTab,
        // AssessementTabs,
        // CardsLoader,
        upgradePlan,
    },
    props: {
        addNewAssessment: Function,
        removeAssessment: Function,
        recommendations: Array,
        placeholders: Array,
        selectedOption: String,
    },
    setup() {
        const Store = useStore();
        return { Store };
    },
    watch: {
        allAssessments: {
            handler(newAssessments) {
                this.getRecommended();
                this.filteredAssessments = newAssessments.filter((assessment) => {
                    return assessment.name.toLowerCase().includes(this.searchPayload.toLowerCase());
                });
                this.isLoading = false;
            },
            immediate: true, // This ensures the handler runs immediately on component mount
        },
        selectedCategories: "filterAssessments",
        searchText: "searchAssessment",
        selectedDurations: "SearchByDuration",
        recommendations: {
            handler() {
                this.getRecommended();
            },
            immediate: true, // Runs once when the component mounts
            deep: true, // Detects deep changes in the array/object
        },
    },
    data() {
        return {
            // showTab: { hard: false, soft: false, psy: false, top: false },
            isLoading: true,
            preview: {},
            isOpen: false,
            showPreview: false,
            showPreviewWindow: false,
            isVisible: false,
            TopAssessement: [],
            personalityTests: [],
            yourAssessment: [],
            allAssessments: [],
            searchPayload: "",
            hardSkills: [],
            softSkills: [],
            psychometrics: [],
            selected: [true, false],
            current: 0,
            score: 0,
            filteredAssessments: this.allAssessments,
            premium: false,
            imagePath: "",
            project_id: "",
            message: "",
            isFixed: false,
            scrollThreshold: 350,
            categories: ["Hard Skills", "Interpersonal Skills", "Behavioral Skills", "Personality"],
            selectedCategories: [],
            libraries: ["Go Platform", "My library"],
            selectedLibraries: [], // By default select both libraries
            searchText: "",
            testDurations: ["Up to 8min", "8min to 12min", "12min to 15min"],
            selectedDurations: [],
            testFucus: ["Verbal Reasoning", "Aptitude", "Numerical Reasoning"],
            selectedFocus: [],
            companies: ["KPMG", "PWC", "Workforce", "Adobe"],
            selectedCompanies: [],
            recommendedAssessments: [],
            filterContainerTop: 11,
        };
    },
    methods: {
        toggleFilter() {
            this.isOpen = !this.isOpen;
        },

        handleScroll() {
            if (this.$refs.libraryWrapper.scrollTop < this.scrollThreshold) {
                this.filterContainerTop = this.$refs.libraryWrapper.scrollTop + 40;
            } else {
                this.filterContainerTop = this.$refs.libraryWrapper.scrollTop + 10;
            }
        },
        getRecommended() {
            this.recommendedAssessments = this.allAssessments.filter((assessment) => {
                // Check if the assessment ID is included in the array of recommended IDs
                return this.recommendations.includes(assessment._id);
            });
        },
        filterAssessments() {
            this.filteredAssessments = this.allAssessments.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const mappedCategories = this.selectedCategories.map((category) => {
                    switch (category) {
                        case "Hard Skills":
                            return "Hard Skills";
                        case "Interpersonal Skills":
                            return "Soft Skills";
                        case "Behavioral Skills":
                            return "Psychometrics";
                        case "Personality":
                            return "Personality";
                        default:
                            return category;
                    }
                });
                const mappedLibraries = this.selectedLibraries.map((library) => {
                    switch (library) {
                        case "Go Platform":
                            return "Go Platform";
                        case "My library":
                            return "My library";

                        default:
                            return library;
                    }
                });
                const matchesLibrary =
                    mappedLibraries.length === 0 || (mappedLibraries.includes("Go Platform") && !assessment.company) || (mappedLibraries.includes("My library") && !!assessment.company);
                const matchesCategory = mappedCategories.length === 0 || mappedCategories.includes(assessment.category);
                const matchesDuration = this.checkDuration(assessment);
                const matchesFocus = this.selectedFocus.length === 0 || this.selectedFocus.some((focus) => assessment.name.includes(focus));
                const matchesCompany = this.selectedCompanies.length === 0 || this.selectedCompanies.some((company) => assessment.name.includes(company));

                return matchesLibrary && matchesSearch && matchesCategory && matchesDuration && matchesFocus && matchesCompany;
            });
        },

        searchAssessment() {
            this.filteredAssessments = this.allAssessments.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const matchesCategory = this.selectedCategories.length === 0 || this.selectedCategories.includes(assessment.category);
                const matchesDuration = this.checkDuration(assessment);
                const matchesFocus = this.selectedFocus.length === 0 || this.selectedFocus.some((focus) => assessment.name.includes(focus));
                return matchesSearch && matchesCategory && matchesDuration && matchesFocus;
            });
        },
        SearchByDuration() {
            this.filteredAssessments = this.allAssessments.filter((assessment) => {
                const matchesSearch = assessment.name.toLowerCase().includes(this.searchText.toLowerCase());
                const matchesCategory = this.selectedCategories.length === 0 || this.selectedCategories.includes(assessment.category);
                const matchesDuration = this.checkDuration(assessment);
                const matchesFocus = this.selectedFocus.length === 0 || this.selectedFocus.some((focus) => assessment.name.includes(focus));
                return matchesSearch && matchesCategory && matchesDuration && matchesFocus;
            });
        },
        checkDuration(assessment) {
            const duration = assessment.questions_nbr > 25 ? parseInt((20 * 35) / 60) : parseInt((assessment.questions_nbr * 35) / 60);
            return (
                this.selectedDurations.length === 0 ||
                this.selectedDurations.some((selectedDuration) => {
                    if (selectedDuration === "Up to 8min" && duration <= 8) {
                        return true;
                    } else if (selectedDuration === "8min to 12min" && duration > 8 && duration <= 12) {
                        return true;
                    } else if (selectedDuration === "12min to 15min" && duration > 12 && duration <= 15) {
                        return true;
                    }
                    return false;
                })
            );
        },

        OpenPreview(test) {
            this.preview = test;
            this.togglePreview();
        },
        togglePreview() {
            this.showPreview = !this.showPreview;
        },
        addAssessment(assessment) {
            this.showPreview = false;
            this.addNewAssessment(assessment);
        },

        async fetchAllAssessments() {
            axios
                .get("https://server.go-platform.com/AssessmentTest/hardSkills", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.hardSkills = res.data.hardSkills;
                    this.Store.setPremium(res.data.premium);
                    this.allAssessments = [...this.allAssessments, ...this.hardSkills];
                });
            axios
                .get("https://server.go-platform.com/AssessmentTest/psychometrics", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.psychometrics = res.data;
                    this.allAssessments = [...this.allAssessments, ...this.psychometrics];
                });
            axios
                .get("https://server.go-platform.com/AssessmentTest/softSkills", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.softSkills = res.data;
                    this.allAssessments = [...this.allAssessments, ...this.softSkills];
                });
            axios
                .get("https://server.go-platform.com/AssessmentTest/topAssessments", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.TopAssessement = res.data;
                    this.allAssessments = [...this.allAssessments, ...this.TopAssessement];
                });
            axios
                .get("https://server.go-platform.com/AssessmentTest/personality", {
                    withCredentials: true,
                })
                .then((res) => {
                    this.personalityTests = res.data;
                    this.allAssessments = [...this.allAssessments, ...this.personalityTests];
                });
        },
    },

    async mounted() {
        this.id = this.$route.query.id;
        this.project_id = this.$route.query.id ? this.$route.query.id : "";
        //this.fetchProjectAssessments(this.id);
        this.fetchAllAssessments().then(() => {
            this.isLoading = false;
            this.getRecommended();
        });
    },
};
</script>

<style scoped lang="scss">
.library-grid-top {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 1rem 0;
    gap: 2rem;
    width: 100%;
}

.fadedCards {
    background: rgb(244, 247, 254);
    background: linear-gradient(0deg, rgba(244, 247, 254, 1) 37%, rgba(255, 255, 255, 0) 100%);
    z-index: 5;
}

.libraryWrapper {
    // display: grid;
    // grid-template-columns: 1fr 3fr;
    // height: 100vh;

    // overflow: scroll;
    .library {
    }
}

.library::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
}

.library::-webkit-scrollbar-track {
    background-color: #2195f328;
    /* Color of the scrollbar track */
    border-radius: 10px;
    /* Rounded corners */
}

.library::-webkit-scrollbar-thumb {
    background-color: #2196f3;
    /* Color of the scrollbar thumb */
    border-radius: 10px;
    /* Rounded corners */
}

.library::-webkit-scrollbar-thumb:hover {
    background-color: #2195f328;
    /* Color of the scrollbar thumb on hover */
}

// .fixed-wrapper {
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 20%;
//     z-index: 999;
//     background-color: #fff;
//     padding: 10px;
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
// }

.categories {
    gap: 8px;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-right: 20px;
    /* Adjust spacing between checkboxes */
    margin-bottom: 10px;
}

.checkbox-input {
    margin-right: 5px;
    /* Adjust spacing between checkbox and label */
    background-color: #2196f3;
}

.checkbox-text {
    margin-left: 5px;
    /* Adjust spacing between checkbox and label */
    font-size: 15px;
    font-weight: 400;
}

.input_group {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // padding: 0 1rem;
    border-radius: 8px;
    width: 100%;

    label {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 32px;
        color: #05152e;
        padding: 0;

        img {
            margin-right: 5px;
        }
    }

    .required {
        &::after {
            content: "*";
            color: #ff6969;
        }
    }

    .floating_label {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 15px;
        padding: 0 1% 0 0;
        transform: translateY(-50%);
        pointer-events: none;
        background: transparent;
        transition: all 0.3s ease;
    }

    .floating_label.active {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        font-size: 12px;
        // width: 10%;
        background: transparent;

        transform: translateY(-110%);
    }

    input:focus + .floating_label {
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        border-radius: 50px;
        // width: 10%;
        background: transparent;
        outline: none;
        border: none;
        transform: translateY(-110%);
        font-size: 10px;
    }

    input,
    textarea {
        border-bottom: 1px solid #ccd7ec;
        box-shadow: 0px 4px 10px rgba(21, 60, 245, 0.04);
        // border-radius: 8px;
        width: 100%;
        padding: 0 1.5rem;

        &:focus {
            outline: none;
            border-bottom: 1px solid #2196f3;
        }
    }

    input {
        height: 3rem;
        background: #ffffff85;
    }

    textarea {
        resize: none;
        padding-top: 1rem;
    }

    input[type="file"] {
        display: none;
    }

    .phone {
        height: 100%;
        border-radius: 20px;
        padding: 2%;

        & > :nth-child(2) {
            // border: 2px solid red;
            border-radius: 20px;
        }
    }
}

.loader {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
