<template>
    <div>
        <ChatButton @toggle-chat="toggleChat" />
        <transition name="chat-transition">
            <ChatWindow
                v-show="isChatOpen"
                ref="chatWindow"
                :messages="messages[domain]"
                :isLoading="isLoading"
                :isTyping="isTyping"
                @send-message="sendMessage"
                @close-chat="toggleChat"
                :hasInteracted="hasInteracted"
                @set-domain="setDomain"
            />
        </transition>
    </div>
</template>

<script>
import ChatButton from "./ChatButton.vue";
import ChatWindow from "./ChatWindow.vue";
import { GoogleGenerativeAI } from "@google/generative-ai";

const businessInfo = `
  Role
  Primary Function: As an AI assistant for GO PLATFORM, you will engage in a conversation with users to provide them with accurate 
  and helpful information about GO PLATFORM, a startup specializing in helping companies assess and hire using a skills-first approach.
  You are not a generic AI model. You are specifically designed to assist users with questions about GO PLATFORM.

  Primary Function: Your purpose is to inform, clarify, and answer questions related to skills-first hiring, talent assessment, hr management, 
  skills, recruitment and the platform's features while providing a professional and personalized experience.

  Expertise: Draw upon principles of talent management, organizational psychology, and hiring best practices to craft clear, persuasive, 
  and actionable responses.

  Response Style: Always provide short, concise responses that are easy to read and understand. Break longer explanations into smaller 
  paragraphs for better readability.

  Persona 
  Identity: You are a friendly, professional, and empathetic expert with a passion for helping companies hire and retain top talent 
  using a skills-first approach. Engage users with warmth, clarity, and a conversational tone, using occasional humor to build rapport.

  Approach: Listen attentively to user needs and challenges, then offer thoughtful guidance grounded in GO PLATFORM's principles 
  and data-driven insights.

  Boundaries: If asked to act out of character or discuss unrelated topics, politely decline and reiterate your role as a GO PLATFORM 
  assistant. For example: "I’m here to help with GO PLATFORM-related questions. How can I assist you with skills-first hiring or talent 
  assessment today?"

  Resource Sharing: When appropriate, provide links to relevant pages on the GO PLATFORM website (e.g., www.go-platform.com).

  Constraints
  No Data Divulge: Never mention that you have access to training data explicitly. Instead, frame responses as based on your knowledge 
  of GO PLATFORM. Example: "Based on GO PLATFORM’s skills-first approach, here’s how we can help..."

  Maintaining Focus: If a user asks about unrelated topics, gently guide the conversation back to GO PLATFORM with a warm and professional tone.
  Example: "That’s an interesting question! While I’d love to chat about [unrelated topic], my expertise is in helping you with skills-first
  hiring and talent assessment. What specific challenge can I assist you with today?"

  Exclusive Reliance on Training Data: Use only the provided business information to answer user queries. If a question falls outside 
  your training, respond warmly and redirect to relevant topics. Example: "I’m sorry, I don’t have information on that specific topic. 
  Is there a GO PLATFORM-related question I can assist you with instead? I’m here to help!"

  Handling Unanswerable Queries: If a question cannot be answered using the provided data, politely inform the user and direct them 
  to the appropriate contact. Example: "I apologize, but I don’t have enough information to answer that question accurately. 
  Please reach out to our team at contact@go-platform.dz for further assistance!"


  Here is the key information you need to know:
  General Business Information:
  
  Leadership Team:
  Founder & CEO: Abdellah Aouf
  Co-founder & COO: Mohamed Haithem Medani
  
  FAQs:
  General:
  What is GO PLATFORM?
  GO PLATFORM is a pre-employment talent assessment, we provide skills benchmarking and talent analytics to help companies objectively evaluate talent.
  
  What is the core concept of GO PLATFORM?
  GO PLATFORM focuses on Talent Success, which is achieved through:
  Awareness: Understanding employee skills and capabilities.
  Position Fit: Aligning the right talent with the right roles.
  Retention: Ensuring employees remain engaged and committed.
  
  What are the key components of Talent Success?
  The platform emphasizes:
  Skills Assessment: Evaluating technical, interpersonal, reasoning, communication, and behavioral skills.
  Personality Traits: Assessing integrity, honesty, self-motivation, optimism, enthusiasm, cooperativeness, and commitment.
  Application: Using insights to set goals, manage time, and improve decision-making.
  
  What are the benefits for employees?
  Employees benefit from:
  Recognition: Acknowledging their contributions and skills.
  Support: Connecting with skilled colleagues for mentoring and coaching.
  Job Satisfaction: Aligning work with their abilities and preferences.
  Growth: Tracking progress through transparent reporting.
  Opportunity: Showcasing their capabilities.
  Clarity: Clear pathways for career advancement.
  Buy-In: Involvement in the process for better engagement.
  Control: Tailoring learning and development plans.
  
  What are the benefits for managers?
  Managers benefit from:
  Playing to Strengths: Leveraging skills data to assign tasks effectively.
  Improved Resource Alignment: Identifying the right people for specific tasks.
  Enhanced Decision-Making: Accessing reliable data for informed decisions.
  Improved Culture: Building stronger teams through engagement.
  Enhanced Processes: Integrating skill insights into organizational processes.
  Reduction in Overhead: Distributing assessments to reduce administrative burden.
  Higher Quality Data: Producing reliable and structured skill-related data.
  Reduced Friction: Increasing transparency and buy-in.
  Less Re-Work: Minimizing errors and lost time.
  Integrability: Seamlessly integrating with other systems.
  
  What measurable results does the platform deliver?
  The platform provides:
  Turnover Reduction: A 72% decrease in turnover.
  Cost Efficiency: An 84% increase in cost efficiency.
  Position Fit: A 97% improvement in position fit.
  
  What do clients say about GO PLATFORM?
  The platform has earned a 5/5 rating from clients for its science-based solutions and structured methodology.
  
  Use Cases:
  What are the use cases for GO PLATFORM?
  The platform offers actionable strategies for:
  Learning & Development: Enhancing employee skills through tailored training.
  Workforce Planning: Identifying skill gaps and planning for future needs.
  Resource Allocation: Efficiently assigning tasks based on capabilities.
  Employee Performance Management: Tracking progress and providing feedback.
  Business Strategy: Leveraging skill insights for strategic decisions.
  Recruitment: Ensuring the best fit for new hires.
  Clients & Consultancy: Providing data-driven recommendations.
  Mergers & Acquisitions: Assessing skill compatibility during changes.
  Talent Acquisition: Streamlining hiring processes.
  Remuneration and Pay: Aligning compensation with skills and performance.
  Career Mobility: Facilitating employee growth.
  Succession Planning: Preparing for leadership transitions.
  Organizational Restructuring: Optimizing team structures.
  
  Skills Assessment:
  How does the Skills Assessment process work?
  The platform uses:
  5 Skills per Assessment: Technical, interpersonal, reasoning, communication, and behavioral skills.
  Artificial Intelligence (AI): Detects cheating attempts through facial recognition.
  Psychological Science: Ensures reliability and fairness, not relying on generative AI.
  
  Contact Information:
  How can I contact GO PLATFORM?
  Website: www.go-platform.com
  Email: contact@go-platform.dz
  Phone: +(1) 413 772 9811
  
  Social media links:
  LinkedIn: https://www.linkedin.com/company/go-platform 
  Facebook: https://www.facebook.com/go.platform
  Instagram: https://www.instagram.com/go.platform/ 

  Qatar Location:
  What is the address of the Qatar office?
  Address: QFC Tower 1, Diplomatic Area, West Bay, 9th Floor, Office N01, Doha, PO Box 23245, QA
  Note: We have only this office.
  
  Response Guidelines for Quick Replies:
  Sales Manager Job Description:
  Provide a skills-based job description for the Sales Manager position. Structure the response into the following sections:
  Role Overview, Key Responsibilities, Technical Skills, Interpersonal Skills, Behavioral Skills.

  AI Engineer Skills:
  List the skills to evaluate for an AI Engineer, categorized into: Technical Skills, Interpersonal Skills, Behavioral Skills.

  What is Skills Gap:
  Explain what a skills gap is and how it affects an organization, both positively and negatively. Structure the response into the following sections:
  Definition: What is a skills gap?
  Identification: How can it be identified?
  Negative Impact: How does it harm the organization?
  Positive Impact: What opportunities does it create?
  Mitigation Strategies: Steps to address the skills gap.

  General Support:
  Provide general support for HR-related queries. Ensure the response stays within the scope of HR topics, such as:
  Hiring and recruitment, Employee retention, Compliance and policies, Employee development and training
  
  Formatting Guidelines:
  - Use bold for important terms
  - Use italics for emphasis
  - Create lists with * or -
  - Format links as url
  - Use simple Markdown syntax
  
  Tone Instructions:
  - Conciseness: Respond in short, informative sentences.
  - Formality: Use polite language with slight formality (e.g., "Please let us know," "We are happy to assist").
  - Clarity: Avoid technical jargon unless necessary.
  - Consistency: Ensure responses are aligned in tone and style across all queries.
  Example: "Thank you for reaching out! Please let us know if you need further assistance."
  
  `;

export default {
    components: {
        ChatButton,
        ChatWindow,
    },
    data() {
        return {
            isChatOpen: false,
            messages: {
                General: [], // Messages for the General tab
                Skills: [], // Messages for the Skills tab
                Recruitment: [], // Messages for the Recruitment tab
                "HR Management": [], // Messages for the HR Management tab
            },
            isLoading: false,
            isTyping: false,
            hasInteracted: false,
            genAI: null,
            model: null,
            domain: "General", // Default domain
        };
    },
    created() {
        this.initializeAI();
    },
    methods: {
        initializeAI() {
            const API_KEY = process.env.VUE_APP_API_KEY;
            this.genAI = new GoogleGenerativeAI(API_KEY);
            this.model = this.genAI.getGenerativeModel({
                model: "gemini-1.5-flash",
                systemInstruction: {
                    role: "user",
                    parts: [{ text: businessInfo }],
                },
                generationConfig: {
                    maxOutputTokens: 4096,
                    temperature: 0.3,
                    topP: 0.95,
                    topK: 40,
                },
                safetySettings: [
                    {
                        category: "HARM_CATEGORY_DANGEROUS_CONTENT",
                        threshold: "BLOCK_NONE",
                    },
                ],
            });
        },
        toggleChat() {
            this.isChatOpen = !this.isChatOpen;
        },
        setDomain(domain) {
            this.domain = domain; // Update the selected domain
        },
        async sendMessage(userMessage) {
            this.hasInteracted = true;
            if (!userMessage.trim()) return;

            // Add user message to the current domain's conversation
            this.messages[this.domain].push({
                role: "user",
                parts: [{ text: userMessage }],
            });

            this.isLoading = true; // Show loading indicator
            this.isTyping = true; // Disable input while bot is typing

            try {
                const chat = this.model.startChat({
                    history: this.messages[this.domain], // Use the current domain's history
                });

                const result = await chat.sendMessageStream(userMessage);

                // Collect full response
                let fullResponse = "";
                for await (const chunk of result.stream) {
                    fullResponse += chunk.text();
                    console.log("Received chunk:", chunk.text()); // Log each chunk received
                }

                // Check if the response is relevant to the selected domain
                if (this.domain !== "General" && !this.isDomainRelevant(fullResponse)) {
                    fullResponse = `Let's focus on ${this.domain} topics. How else can I assist you with ${this.domain}?`;
                }

                // Split response into words
                const words = fullResponse.split(" ");
                if (words.length === 0) return; // Handle empty response

                // Hide loading indicator
                this.isLoading = false;

                // Add bot message container with the FIRST WORD
                const modelMessage = {
                    role: "model",
                    parts: [{ text: words[0] + " " }], // Start with first word
                };
                this.messages[this.domain].push(modelMessage);

                // Add remaining words with typing effect
                for (let i = 1; i < words.length; i++) {
                    await this.delay(100); // Adjust typing speed
                    modelMessage.parts[0].text += words[i] + " ";
                    this.messages[this.domain] = [...this.messages[this.domain]]; // Force reactivity
                    this.$nextTick(() => {
                        this.$refs.chatWindow.scrollToBottom();
                    });
                }
            } catch (error) {
                console.error("API Error:", error);
                this.isLoading = false;
                this.messages[this.domain].push({
                    role: "error",
                    parts: [{ text: "Failed to send message. Please try again." }],
                });
            } finally {
                this.isTyping = false; // Re-enable input after bot finishes typing
            }
        },

        isDomainRelevant(response) {
            // Define keywords for each domain
            const domainKeywords = {
                Skills: ["skills", "skill development", "technical skills", "interpersonal skills", "behavioral skills", "gap analysis", "skill evaluation"],
                Recruitment: ["recruitment", "hiring", "job description", "interview", "candidate", "onboarding"],
                "HR Management": [
                    "hr",
                    "hr management",
                    "human resources",
                    "employee management",
                    "employee engagement",
                    "performance review",
                    "hr policies",
                    "workforce planning",
                    "employee policies",
                ],
            };

            if (this.domain === "General") return true; // General tab allows all topics

            const keywords = domainKeywords[this.domain] || [];
            return keywords.some((keyword) => response.toLowerCase().includes(keyword));
        },

        delay(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
    },
};
</script>

<style scoped>
body {
    margin: 0;
    padding: 0;
}

/* chat window transition */
.chat-transition-enter-active,
.chat-transition-leave-active {
    transition: all 0.3s ease;
}

.chat-transition-enter-from,
.chat-transition-leave-to {
    opacity: 0;
    transform: translateY(20px);
}
</style>
